<template>
    <div>
        <div class="alert alert-warning px-3 py-1" v-if="alertMessage">{{ alertMessage }}</div>
        <form id="searchPTV" @submit.prevent="handlePTVSubmit">
            <label for="plate">{{ fcoM['rs.homeMain.lookupbystate'] || '&nbsp;' }}</label>
            <div class="d-flex">
                <div class="vs-state-select" v-if="!vsData?.states?.length || !defaultStateValue">
                    <VueSkeletonLoader type="rect" :radius="3" :rounded="true" width="100%" height="100%" class="custom-select" />
                </div>
                <FcoSearchSelect
                    v-else
                    :value="plateState"
                    :allow-empty="false"
                    :options="vsData.states"
                    :placeholder="tempState"
                    class="vs-state-select"
                    data-qa="ptv-state-select"
                    :menu-container="$refs.stateSelect"
                    ref="stateSelect"
                    @focus="handleStateFocus"
                    @blur="handleBlur"
                    @select="handleStateSelect"
                />
                <div class="input-group">
                    <input
                        type="text"
                        id="plate"
                        data-qa="ptv-plate-input"
                        :class="{ error: showPtvValidationError }"
                        v-model="licensePlate"
                        :placeholder="fcoM['common.vehicleLicensePlate']"
                    />
                    <button class="gtm-license-number button input-group-append px-2 px-xl-3 py-1 mb-0" data-qa="ptv-submit-button">
                        <span class="d-sm-none d-lg-inline">{{ fcoM['common.go'] || '&nbsp;' }}</span>
                        <Icon name="chevron-right" class="gtm-license-number-svg" />
                    </button>
                </div>
            </div>
            <span v-if="showPtvValidationError" class="error">
                {{ showPtvValidationErrorMessage }}
            </span>
        </form>

        <div>
            <QuoteSelectionModal
                v-if="showNewQuotePrompt"
                @close="showNewQuotePrompt = false"
                @start-new-quote="handleQuotePromptAnswer(false)"
                @use-existing-quote="handleQuotePromptAnswer(true)"
            />
        </div>

        <div>
            <FcoModal
                v-show="showModal"
                :titleText="fcoM['rs.homeMain.pleaseSelectVehicle']"
                @close="showModal = false"
                :closeMsg="fcoM['common.close']"
            >
                <template #body>
                    <div v-if="showModalLoading" class="text-center">
                        <Icon color="green" name="refresh" class="m-3" size="large" />
                    </div>
                    <div v-else>
                        <span>{{ mainMessage }}</span>
                        <ul v-if="vehicles.length">
                            <li v-for="vehicle in cleanVehicleList" :key="vehicle.vin" class="my-1" data-qa="ptv-modal-results-list">
                                <a href="javascript:void(0)" @click="handleVehicleClick(vehicle)" data-qa="ptv-modal-vehicle-link">{{
                                    vehicle.yearMakeModel
                                }}</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="forceNewRequest" data-qa="ptv-modal-no-match-link">{{
                                    fcoM['rs.homeMain.noneAreVehicle']
                                }}</a>
                            </li>
                        </ul>
                    </div>
                </template>
                <template #footer>
                    <button type="button" class="button secondary" @click="showModal = false" data-qa="close-modal-button">
                        {{ fcoM['common.close'] }}
                    </button>
                </template>
            </FcoModal>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getStorageItem, setStorageItem, StorageKey } from '@/common/store/store-utils';
import { FcoModal } from 'fco/src/vue/components/modal';
import { FcoSearchSelect } from 'fco/src/vue/components/searchselect';
import axios from 'axios';
import VueSkeletonLoader from 'skeleton-loader-vue';
import QuoteSelectionModal from './QuoteSelectionModal.vue';
import Icon from '../Icon.vue';
import vsMixin from '../../mixins/vsMixin';

const licensePlateRegExp = /^[a-zA-Z0-9]+[a-zA-Z/#'",.\-! 0-9]*[a-zA-Z0-9]+$/g;

export default {
    name: 'PlateToVin',
    mixins: [vsMixin],
    data() {
        return {
            licensePlate: '',
            plateState: '',
            ptvForce: false,
            showError: false,
            showPtvValidationError: false,
            showPtvValidationErrorMessage: '',
            vehicles: [],
            showModal: false,
            showModalLoading: false,
            alertMessage: '',
            tempState: '',
            showNewQuotePrompt: false,
            tempVehicle: null,
        };
    },
    components: { FcoModal, Icon, FcoSearchSelect, QuoteSelectionModal, VueSkeletonLoader },
    computed: {
        ...mapState({
            requests: ({ requests }) => requests,
            currentShopState: ({ currentShop }) => currentShop?.state,
        }),

        cleanVehicleList() {
            return this.vehicles.map((vehicle) => ({
                plate: vehicle.plate,
                vin: vehicle.vin,
                vid: vehicle.vinQuerySet.map((data) => data.vehicle.vehicleId).toString(),
                yearMakeModel: vehicle.vinQuerySet.map((data) => `${data.vehicle.year} ${data.vehicle.make} ${data.vehicle.model}`).toString(),
            }));
        },
        mainMessage() {
            let msg = '';

            if (this.vehicles.length < 1) {
                msg = this.fcoM['rs.homeMain.noVehicleFound'];
            } else if (this.vehicles.length === 1) {
                msg = this.fcoM['rs.homeMain.correctVehicleQuestion'];
            } else {
                msg = this.fcoM['rs.homeMain.pleaseSelect'];
            }

            return msg;
        },
        defaultStateValue() {
            // if we don't have a cached, last-selected state, use the current shop's state as the default
            return getStorageItem(StorageKey.PTV_LAST_STATE) || this.currentShopState;
        },
    },
    methods: {
        async handlePTVSubmit() {
            this.showPtvValidationError = false;

            // A RegExp object with the g flag keeps track of the lastIndex where a match occurred, so on subsequent
            // matches it will start from the last used index, instead of 0
            licensePlateRegExp.lastIndex = 0;
            const licensePlateMask = licensePlateRegExp.test(this.licensePlate.trim());

            if (!this.licensePlate.trim().length) {
                this.showPtvValidationError = true;
                this.showPtvValidationErrorMessage = this.fcoM['validation.required'];
            } else if (!licensePlateMask) {
                this.showPtvValidationError = true;
                this.showPtvValidationErrorMessage = this.fcoM['validation.invalidInput'];
            } else {
                this.analytics({
                    event: this.GTM.Action.VEHICLE_SELECTOR,
                    data: {
                        eventAction: 'License Plate Search',
                    },
                });

                this.ptvForce = false;
                this.alertMessage = '';
                this.showModalLoading = true;
                this.showModal = true;

                try {
                    const tempURL = this.fcoUrl(`/plateToVin/decodePlate?state=${this.plateState}&plate=${this.licensePlate}`);
                    const {
                        data: { force, serviceDown, vehicles },
                    } = await axios.get(tempURL);

                    if (serviceDown) {
                        this.handleServiceDown();
                    } else {
                        this.vehicles = vehicles;
                        this.ptvForce = force;
                        this.showModalLoading = false;

                        if (this.vehicles.length > 0) {
                            this.analytics({
                                event: this.GTM.Action.VEHICLE_SELECTOR,
                                data: {
                                    eventAction: 'License Plate Found',
                                },
                            });
                        }
                    }
                } catch (error) {
                    this.alertMessage = this.fcoM['rs.homeMain.ptvError'];
                }
            }
        },
        handleQuotePromptAnswer(keepExistingQuote) {
            this.userWantsExistingQuote = keepExistingQuote;
            this.showNewQuotePrompt = false;
            this.handleVehicleClick(this.tempVehicle, true);
        },
        handleVehicleClick(vehicle, ignoreQuoteCheck = false) {
            if (this.productsInCurrentQuote && this.promptAboutQuote && !ignoreQuoteCheck) {
                this.showNewQuotePrompt = true;
                this.tempVehicle = vehicle;
                return;
            }

            this.selectVehicle(vehicle);
        },
        async selectVehicle({ plate, vin, vid }) {
            this.showModalLoading = true;
            try {
                if (this.preventNavigationOnVehicleUpdate) {
                    this.showModalLoading = false;
                    this.showModal = false;
                }
                const {
                    data: { vehicle },
                } = await axios.post(this.fcoUrl(`/vehicle/select/selectFromPTV?quote=${this.keepExistingQuoteFinal}`), {
                    vehicleId: vid,
                    plate,
                    vin,
                });
                if (this.isSPA) {
                    this.showModal = false;
                }
                this.handleVehicleSelectSuccess({ gaEventAction: 'ptv', vehicle });
                this.licensePlate = '';
            } catch (error) {
                this.$fcoToast.error(this.fcoM['rs.managecustomerdetails.errorSavingVehicle']);
                this.showModal = true;
                this.showModalLoading = false;
            }
        },
        async forceNewRequest() {
            this.showModalLoading = true;
            let url = this.fcoUrl(`/plateToVin/decodePlate?state=${this.plateState}&plate=${this.licensePlate}&force=true`);

            if (this.ptvForce) {
                url += '&wasForced=true';
            }

            try {
                const {
                    data: { vehicles, force, serviceDown },
                } = await axios.get(url);

                if (serviceDown) {
                    this.handleServiceDown();
                } else {
                    this.vehicles = vehicles;
                    this.ptvForce = force;
                    this.showModalLoading = false;
                }
            } catch (err) {
                this.$fcoToast.error(this.fcoM['rs.homeMain.ptvError']);
            }
        },
        handleStateSelect(data) {
            this.plateState = data.value;
            setStorageItem(StorageKey.PTV_LAST_STATE, this.plateState);
            this.analytics({
                event: this.GTM.Action.VEHICLE_SELECTOR,
                data: {
                    eventAction: 'PTV State Select',
                },
            });
        },
        handleServiceDown() {
            this.alertMessage = this.fcoM['rs.homeMain.ptvError.noService'];
            this.showModal = false;
        },
        handleStateFocus() {
            this.tempState = this.plateState;
            this.plateState = '';
        },
        handleBlur() {
            if (this.plateState === '') {
                this.plateState = this.tempState;
            }

            this.tempState = '';
        },
    },
    async mounted() {
        // Need to wait until we have the list of state options before we can select one
        await this.$store.dispatch('requestIfIdle', ['vehicleSelector/getVehicleSelectorData']);

        if (!this.defaultStateValue) {
            // since we don't have a cached state, we need to wait for the current shop to be loaded to use it's state instead
            await this.$store.dispatch('requestIfIdle', ['getCurrentShop']);
        }

        this.handleStateSelect({ value: this.defaultStateValue });

        this.$watch('currentShopState', (shopState) => {
            if (!shopState) return;
            setStorageItem(StorageKey.PTV_LAST_STATE, shopState);
        });
    },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/config';
@import '~scssVariables/mixins';

input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
}
input:not(:focus) + .button:not(:focus) {
    background: #fff;
    border-color: $green;
    color: $green;
}

.vs-state-select {
    line-height: 1;
    min-height: auto;
    width: 25%;
    display: flex;
    border-radius: 3px 0 0 3px;
    background-position: right 0.2rem center;

    @include respond(sm, md) {
        width: 40%;
    }

    ::v-deep {
        .search-select-typeahead {
            margin: auto 0;
        }
        .typeahead-input {
            font-size: 0.75rem;
            background-image: none;
        }

        .dropdown-toggle {
            vertical-align: bottom;
        }

        .fco-dropdown-menu {
            max-height: 250px;
            overflow: auto;

            .search-results a {
                font-size: 0.75rem;
                text-decoration: none;
            }
        }
    }
}
</style>
