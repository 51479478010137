import store from '../store/store';
import en from '../../locales/en.json';
import { Locale } from '../constants/user';
import { setLivePersonLocale } from '../utilities/liveperson';

// List of additional message resources by locale.
// This should represent a complete index of languages we support
const getLocaleMessages = {
    [Locale.ES]: () => import('../../locales/es.json'),
};
const cachedMessageRequests = {};

export const storageLocaleKey = 'locale';

/**
 * Internationalization Plugin for FCO
 *      - registers a Vuex store module: 'i18n'
 *          - state contains the current locale and messages for that locale
 *          - changeLocale() action to switch locales and get updated messages
 *          - localeOptions getter provides list of value/label pairs of supported locales
 *          - other getters for checking against specific locales
 *      - adds the `fcoM` global mixin for referencing messages by key
 */
export default {
    install(Vue) {
        const isLocaleEnglish = (locale) => locale === Locale.EN;
        const isLocaleSpanish = (locale) => locale === Locale.ES;
        const isValidLocale = (locale) => isLocaleEnglish(locale) || Object.prototype.hasOwnProperty.call(getLocaleMessages, locale);

        store.registerModule('i18n', {
            namespaced: true,
            state: {
                locale: Locale.EN,
                messages: { ...en },
            },
            mutations: {
                setLocale(state, locale) {
                    if (!isValidLocale(locale)) return;
                    state.locale = locale;
                },
                setMessages(state, messages) {
                    state.messages = messages;
                },
            },
            actions: {
                async changeLocale({ commit, state }, { locale = Locale.EN, cache = true } = {}) {
                    // If we don't have messages for a given locale, fall back to English.
                    const validLocale = !isValidLocale(locale) ? Locale.EN : locale;

                    if (validLocale === state.locale) return;

                    // TODO remove this line when we stop using LivePerson
                    setLivePersonLocale(locale);

                    // This check allows the language to be changed without saving the selection in localStorage
                    // (i.e. if feature is not enabled but user changes the language on the terms & conditions page)
                    if (cache) localStorage.setItem(storageLocaleKey, validLocale);

                    if (isLocaleEnglish(validLocale)) {
                        commit('setMessages', { ...en });
                        commit('setLocale', validLocale);
                        return;
                    }

                    // Go get the messages for the locale. Check the cache first to improve performance.
                    // Prevent a race condition on requests by caching the request itself and awaiting that.
                    if (!cachedMessageRequests[validLocale]) {
                        cachedMessageRequests[validLocale] = getLocaleMessages[validLocale]();
                    }

                    const { default: localeMessages } = await cachedMessageRequests[validLocale];
                    commit('setMessages', { ...en, ...localeMessages });
                    commit('setLocale', validLocale);
                },
                async selectDefaultLocale({ dispatch }, localeOverride) {
                    const cachedLocale = localStorage.getItem(storageLocaleKey);
                    const browserDefaultLocale = navigator.language;
                    const defaultLocale = (localeOverride || cachedLocale || browserDefaultLocale || Locale.EN).split('-')[0].toLowerCase();
                    return dispatch('changeLocale', { locale: defaultLocale });
                },
            },
            getters: {
                localeOptions: (state) => [
                    { value: Locale.EN, label: state.messages['common.english'] || '' },
                    { value: Locale.ES, label: state.messages['common.espanol'] || '' },
                ],
                isLocaleEnglish: ({ locale }) => isLocaleEnglish(locale),
                isLocaleSpanish: ({ locale }) => isLocaleSpanish(locale),
            },
        });

        // Register the `fcoM` mixin for referencing messages by key in Vue components
        Vue.mixin({
            computed: {
                fcoM() {
                    return new Proxy(this.$store.state.i18n.messages, {
                        get(...args) {
                            return Reflect.get(...args) ?? '';
                        },
                    });
                },
            },
        });
    },
};
